.priceCalculatorFieldsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: 768px) {
    margin-right: 1.5rem;
  }

  @media (min-width: 1280px) {
    width: 32.5rem;
    margin-right: 2rem;
  }
}

.priceCalculatorFieldStateContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: 1280px) {
    width: 32.5rem;
  }
}

.priceCalculatorFieldCobContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: 1280px) {
    width: 32.5rem;
  }
}

.priceCalculatorFieldLobsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: 1280px) {
    width: 32.5rem;
  }
}

.priceCalculatorCalculationFieldContainer {
  //margin-bottom: 1rem;
}

.priceCalculatorCalculationFieldInternalContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 1.5rem;
  height: 8rem;
  width: 100%;
  align-items: center;

  @media (min-width: 1280px) {
    width: 32.5rem;
    height: 8.813rem;
  }
}

.priceCalculatorBottomSection {
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;

  @media (min-width: 1280px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.priceCalculatorBottomText {
  line-height: 1.02;
  letter-spacing: -0.01562em;
  font-family: 'Graphik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.75rem;
  text-align: left;
  padding-bottom: 4rem;
}

.stateQuoteText {
  line-height: 1.02;
  letter-spacing: -0.025em;
  font-family: 'Graphik Web', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.25rem;
  text-align: center;
  padding-top: 2.5rem;

  @media (min-width: 1024px) {
    padding-top: 3rem;
  }
}

.priceLabel {
  line-height: 1.02;
  letter-spacing: -0.025em;
  font-family: 'GT Alpina', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 0.5rem;
  font-size: 2.25rem;

  @media (min-width: 640px) {
    font-size: 2.375rem;
  }

  @media (min-width: 1024px) {
    font-size: 4rem;
  }
}
