.priceCalculatorSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFD2B9;

  padding-top: 5.5vw;
  padding-right: 5.5vw;
  padding-left: 5.5vw;

  @media (min-width: 640px) {
    padding-top: 6.510vw;
  }

  @media (min-width: 768px) {
    padding-top: 7.8125vw;
    padding-right: 7.8125vw;
    padding-left: 7.8125vw;
    padding-bottom: 0;
  }

  @media (min-width: 1024px) {
    padding-top: 5.555vw;
  }

  @media (min-width: 1280px) {
    padding-top: 5.5vw;
    padding-right: 5.5vw;
    padding-left: 5.5vw;
  }
}

.priceCalculatorTable {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

