.referralPageWrapper {
  -webkit-font-smoothing: antialiased;
  margin-top: -1rem;
  @media (max-width: 768px) {
    margin-top: -4rem;
  }
  @media (min-width: 1024px) {
    margin-top: -4rem;
  }


  .referralHeader {
    padding-left: 5vw;
    height: 10.8vh;
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
      height: 56px;
    }
  }

  .referralContainer {
    display: flex;
    min-height: 80.6vh;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    .amazonWrapper {
      width: 50%;
      background: #00D7FF;
      padding: 11vw 4vw 4vw 4vw;
      @media (max-width: 768px) {
        width: 100%;
        padding-bottom: 30vw;
        position: relative;
      }

      .amazonInsideWrapper {
        width: 100%;

        .titleAndSubtitleWrapper {
          margin-right: auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;

          .mainTitle {
            line-height: 54.4px;
            font-size: 64px;
            color: #000;
            font-family: Sharp Sans Display No2, serif;
            margin-bottom: 25px;
            padding-right: 25px;
            @media (max-width: 768px) {
              font-size: 42px;
              line-height: 37.4px;
            }
          }

          .subTitle {
            color: #000;
            font-family: 'Graphik Web', 'sans-serif', serif;
            font-size: 21px;
            line-height: 28px;
            @media (max-width: 768px) {
              font-size: 15px;
              line-height: 22.4px;
            }
          }
        }

        .amazonCardWrapper {
          margin-left: auto;
          margin-top: 40px;
          width: 60%;
          margin-right: 40px;
          @media (max-width: 768px) {
            position: absolute;
            margin-top: 0;
            margin-right: auto;
            margin-left: auto;
            width: 80%;
            bottom: -30vw;
            right: 50%;
            transform: translateX(50%);
          }
        }
      }
    }

    .referralContentWrapper {
      width: 50%;
      background: #fff;
      padding-top: 11vw;
      padding-left: 30px;
      padding-right: 30px;

      @media (max-width: 768px) {
        width: 100%;
        padding: 33vw 15px 15px;
      }

      .nameTitle {
        color: #000;
        font-family: 'Graphik Web', 'sans-serif', serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 22.4px;
        }
      }

      .nameTitleLoader {
        width: 50px;
        height: 20px;
        display: block;
      }

      .iconGroupWrapper {
        margin-bottom: 30px;

        .iconTextWrapper {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          .iconWrapper {
            width: 50px;
            display: block;
            margin-right: 20px;
            @media (max-width: 768px) {
              width: 36px;
            }
          }

          .iconTitleWrapper {
            color: #000;
            font-family: 'Graphik Web', 'sans-serif', serif;
            font-size: 20px;
            line-height: 28px;
            width: 80%;
            @media (max-width: 768px) {
              font-size: 16px;
              line-height: 22.4px;
            }
          }
        }
      }

      .postRevealLinkWrapper {
        position: relative;

        .responsiveIconWrapper {
          position: fixed;
          top: -90px;
          right: 50%;
          transform: translateX(50%);
          width: 300px;
          transition: all 0.3s ease;
          pointer-events: none;
          opacity: 0;

          &.showCopied {
            top: 0;
            opacity: 1;
          }
        }

        .linkTitle {
          color: #000;
          font-family: 'Graphik Web', 'sans-serif', serif;
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 20px;
          font-weight: 600;
          display: block;
          @media (max-width: 768px) {
            font-size: 16px;
            line-height: 22.4px;
          }

        }

        .linkWrapper {
          height: 52px;
          width: 100%;
          max-width: 670px;
          border: 1px solid #D1D3D4;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15px;
          transition: all 0.3s ease;

          .linkText {
            color: #1564EC;
            font-family: 'Graphik Web', 'sans-serif', serif;
            font-size: 20px;
            line-height: 32px;
            font-weight: 600;
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            @media (max-width: 768px) {
              font-size: 16px;
              line-height: 24px;
            }
          }

          .copyLinkIcon {
            transition: all 0.3s ease;
            width: 24px;
            cursor: pointer;
          }

          &:hover {
            border-color: #1564EC;

            .copyLinkIcon {
              transform: translateY(-3px);
            }
          }
        }

        .buttonsWrapper {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          @media (max-width: 768px) {
            margin-top: 35px;
            display: block;
          }

          .emailButton {
            margin-right: 15px;
            color: #1564EC;
            font-family: 'Graphik Web', 'sans-serif', serif;
            font-size: 18px;
            line-height: 18px;
            background-color: #fff;
            padding: 13px 24px;
            border-radius: 100px;
            cursor: pointer;
            transition: all 0.3s ease;
            margin-top: 30px;
            margin-bottom: 20px;
            align-items: center;
            width: 250px;
            border: 1px solid #1564EC;
            display: none;
            @media (max-width: 768px) {
              display: flex;
              margin-bottom: 10px;
              margin-top: 15px;
            }

            .emailIconWrapper {
              width: 24px;
              margin-right: 8px;
              transition: all 0.3s ease;
            }

            &:hover {
              .emailIconWrapper {
                transform: translateY(-3px);
              }
            }

          }

          .copyLinkButton {
            margin-right: 15px;
            color: #fff;
            font-family: 'Graphik Web', 'sans-serif', serif;
            font-size: 18px;
            line-height: 18px;
            background-color: #1564EC;
            padding: 12px 24px;
            border-radius: 100px;

            cursor: pointer;
            transition: all 0.3s ease;
            margin-top: 30px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
              margin-top: 15px;
            }

            .copyIconWrapper {
              width: 24px;
              margin-right: 8px;
              transition: all 0.3s ease;
            }

            &:hover {
              .copyIconWrapper {
                transform: translateY(-3px);
              }
            }
          }

        }

        .socialTitle {
          color: #000;
          font-family: 'Graphik Web', 'sans-serif', serif;
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 10px;
          font-weight: 600;
          display: block;
          @media (max-width: 768px) {
            font-size: 16px;
            line-height: 22.4px;
          }
        }

        .socialIconsWrapper {
          display: flex;
          margin-top: 15px;
          margin-bottom: 50px;

          .singleSocial {
            margin-right: 15px;
            cursor: pointer;
            transition: all 0.3s ease;
            width: 64px;

            &:hover {
              transform: translateY(-3px);
            }

            @media (max-width: 768px) {
              width: 48px;
            }
          }
        }
      }

      .preRevealLinkWrapper {
        margin-bottom: 20px;
        margin-top: 40px;

        .getMyReferralButton {
          color: #fff;
          font-family: 'Graphik Web', 'sans-serif', serif;
          font-size: 18px;
          line-height: 28px;
          background-color: #1564EC;
          padding: 12px 24px;
          border-radius: 100px;
          cursor: pointer;
          transition: all 0.3s ease;
          margin-top: 30px;
          margin-bottom: 20px;

          &:hover {
            background-color: #000;
          }

          @media (max-width: 768px) {
            margin-top: 10px;
            font-size: 16px;
            line-height: 22.4px;
            padding: 10px 24px;
          }
        }

        .termsWrapper {
          color: #000;
          font-family: 'Graphik Web', 'sans-serif', serif;
          font-size: 16px;
          line-height: 28px;

          a {
            color: #1564EC;
          }

          @media (max-width: 768px) {
            font-size: 14px;
            line-height: 19.6px;
          }
        }
      }
    }
  }

  .referralFooter {
    font-family: 'Graphik Web', 'sans-serif', serif;
    font-size: 16px;
    line-height: 28px;
    background-color: #231F20;
    height: 8.6vh;
    display: flex;
    align-items: center;
    padding: 0 10px;
    @media (max-width: 768px) {
      height: auto;
      display: block;
      padding: 20px;
    }

    .footerLeftRights {
      width: 50%;
      color: #fff;
      display: flex;
      justify-content: center;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .footerLinks {
      width: 50%;
      color: #fff;
      display: flex;
      justify-content: center;

      a {
        margin-right: 20px;
        @media (max-width: 768px) {
          margin-right: 15px;
        }
      }

      @media (max-width: 768px) {
        width: 100%;
        display: block;
        text-align: center;
      }
    }
  }
}
